.background {
  display: flex;
  justify-content: flex-end;
  background-color: #ff3434;
  color: white;
}

.navitem {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: white !important;
  text-decoration: none;
}

.navitem.logout {
  position: absolute;
  left: 0;
}

.active:hover,
.active:visited,
.active:link {
  background-color: #fd6161 !important;
  text-decoration: none;
}

.navitem:hover {
  background-color: #fd6161;
}