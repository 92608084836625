.tableContainer {
  overflow: scroll;
  margin: 1rem;
  background: #fff;
  max-height: 85vh;
  padding: 10px 0 0 10px;
}

.table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
}

.table td,
.table th {
  border: 1px solid #838383;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.table tr:nth-child(even) {
  background-color: #dadada;
}

.table tr:hover {
  background-color: rgb(243, 243, 243);
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ff4a4a;
  color: white;
  position: relative;
}

.filterSpan>input,
.filterSpan>select {
  padding: 5px 0;
  width: auto;
  margin: 0.5rem 0;
  font-size: 1rem;
}

.toggle-column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


.toggle-column div {
  border: 1px solid grey;
  width: calc(100%/5)
}

/* ********************************************************************* */
.action-container>.action-button:first-child {
  margin-right: 1rem;
}

.action-container>.action-button:nth-child(2) {
  background-color: #e2c9c9;
  color: black;
}

.action-control {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.export-btn {
  align-self: center;
}

.date-input {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
}

.buSelector {
  margin-bottom: 0rem;
  width: max-content;
  align-self: center !important;
  height: 0 !important;
  width: 4rem;
  margin-bottom: 3rem;
  width: fit-content !important;
}

.date-range {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
}

.date-range input {
  width: 100%;
  flex-flow: wrap;
}

.bu-select {
  display: flex;
  flex-direction: column;
}

.filterContainer {
  display: flex;
  justify-content: flex-end;
}

.sortContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

#vehicle1 {
  display: flex;
  margin: auto;
}

.page-entries {
  width: 15%;
  margin: 0 0.5rem;
}