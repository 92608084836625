.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    padding: 0.5rem 0;
    /* border-bottom: 1px solid gray; */
}

.history-data-wrapper {
    margin: 0.5rem 0;
    /* border-top: 1px solid gray; */
    border-bottom: 1px solid gray;
}

.historyData {
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
}

/* .historyDataEntry{
    border: 1px solid red;
} */

/* **************TABLE EDITION*********************** */

.tableContainer {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 1rem;
    border-collapse: collapse;
    width: 100%;
}

.tableContainer tr,
.tableContainer th {
    border: 1px solid black;
    white-space: nowrap;
    font-size: 1rem;
    padding: 0.5rem 1rem;
}

.tableContainer th {
    background-color: #ff4a4a;
    color: white;
    font-size: 1rem;
    padding: 0.5rem 1rem;
}

.tableContainer td {
    border: 1px solid #838383;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    text-align: center;
}