.timeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border: 1px solid; */
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.action-button{
  background-color: #ff3434;
  border: 0.5px solid black;
  color: white;
}

.action-button:disabled{
  background-color: #9f9f9f;
  border: 0.5px solid black;
  cursor:no-drop
}

.action-button:hover{
  background-color: #aa2525;
  border: 0.5px solid black;
}

.vistaarSpoc,.vistaarSpocEmail{
  margin-bottom: 1rem;
}
