.formElement>div {
    margin: 1.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
}

.formElement>div>label {
    margin-bottom: 0.25rem;
}

.formElement>div>input,
.formElement>div>select {
    font-size: 1rem;
    line-height: 1.75rem;
}

.formElement>div>label>input,
.formElement>div>label>select {
    margin-left: 1rem;
}

.formElement>button {
    margin: 0 1rem;
    border-radius: 5px;
    padding: 0.5rem;
    border: 0.5px solid black;
}

strong {
    font-size: 2rem;
}

.actionBtn {
    background-color: rgb(239, 46, 46);
    color: white;
    width: 25%;
}

.actionBtn:hover {
    background-color: rgb(192, 40, 40);
}

.actionBtn:disabled {
    background-color: grey;
}

.secondaryBtn:hover {
    background-color: rgb(177, 177, 177);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}