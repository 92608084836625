* {
  box-sizing: border-box;
  font-size: 1.2rem;
}

.formContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 4rem;
  margin-top: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.2rem;
  border: 1px solid;
  padding: 1rem;
  width: 35rem;
  background: #fff;
}

.btnSubmit {
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
  cursor: pointer;
  color: "white";
  background-color: "#ff3434"
}

.btnSubmit:disabled {
  background-color: #b9b8b8;
  color: white;
}

.btnSubmit:disabled:hover {
  background-color: #9e9e9e;
  color: white;
}

.req:after {
  content: "*";
}

label {
  margin-bottom: 2px;
  font-weight: 600;
}

label>span {
  font-weight: normal;
}

h3 {
  margin: 0.5rem 0;
}

hr {
  color: black;
  border: 1px solid rgb(194, 194, 194);
  width: 100%;
  margin-top: 1.5rem;
}

select,
input,
textarea {
  margin-bottom: 1rem;
  width: 100%;
  padding: 0.5rem;
  font-size: 1.1rem;
  transition: box-shadow 0.1s ease;
}

textarea {
  height: 7rem;
}

select:focus,
input:focus,
textarea:focus {
  box-shadow: 0px 0px 3px 2px #4e4e4e;
  outline: none;
  border-color: transparent;
}