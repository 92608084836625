.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.75);
  animation: backdrop 0.15s forwards ease-in-out;
}

.attachment {
  /* position: fixed;
    left: 75%;
    top: 10%;
    transform: translateX(-63%);
    width: 100vw;
    height: 100vh;
    z-index: 12; */

  position: fixed;
  /* background: red; */
  width: 80%;
  height: 80%;
  z-index: 15;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.embedPdf {
  width: 100%;
  height: 100%;
}

.embedImage {
  width: auto;
  height: 100%;
}
