.closeButton{
    background-color: #ff3434;
}

.closeButton:hover{
    background-color: #aa2525;
}

.buttonDiv{
    margin: 1rem;
}

.buttonDiv> button{
    margin: 1rem;
    height: 3rem;
}
