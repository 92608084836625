.calendarContainer {
  height: 93vh;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.fc {
  background: rgb(243 243 243);
  height: 100%;
  width: 80%;
}

.fc-event-time {
  display: none;
}

.fc-event-main {
  padding-left: 10px;
}

.fc-event-title {
  font-size: 20px;
}

.fc-event-title-container {
  height: 26px;
}

.action-control-calendar {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.buSelector-calendar {
  height: 3rem;
  width: 4rem;
  margin-bottom: 3rem;
  margin-right: 1rem;
  width: 30%;
  align-self: flex-end;
}

.progress-legend {
  margin-bottom: 4rem;
}

.inProgress {
  background-color: blue;
  border-style: ridge;
  width: 300px;
  font-size: 0.5cm;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-left: 10px;
}

.notInitiated {
  background-color: red;
  border-style: ridge;
  width: 300px;
  font-size: 0.5cm;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.testLinkShared {
  background-color: green;
  border-style: ridge;
  width: 300px;
  font-size: 0.5cm;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-left: 10px;
  margin-top: 10px;
}

.activity-type {
  margin-bottom: 3rem;
}

/* Status blob */
.status .fc-event-main {
  position: relative;
  padding-left: 20px;
}

.status .fc-event-main::after {
  position: absolute;
  height: 10px;
  width: 10px;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  border-radius: 50%;
}

.status.red .fc-event-main::after {
  background: red;
}

.status.yellow .fc-event-main::after {
  background: yellow;
}

.status.green .fc-event-main::after {
  background: green;
}

.highPriority .fc-event-main::before {
  content: '';
  position: absolute;
  height: 20px;
  width: 20px;
  background-image: url('./stacked-arrowheads.png');
  background-size: cover;
  /* transform: translateX(150px); */
  top: 2px;
  right: 5px;
}

.fc-event-title {
  width: 80%;
}