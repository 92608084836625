.container {
  border: 1px solid;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loginContainer {
  border: 1px solid;
  padding: 1rem;
  min-width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
}

.changePassBtn,
.loginBtn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1rem;
  background-color: red;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 1rem 0;
}

.btnLink {
  text-decoration: none;
  color: white;
  width: 100%;
  padding: 1rem;
  margin: 0;
}

.errorSpan {
  font-style: italic;
  color: red;
}

h1 {
  width: 100%;
  text-align: center;
}

.authLabel {
  margin-bottom: 0.5rem;
}

.authInput {
  margin-bottom: 2rem;
}

.btnSubmit{
  background-color: #ff3434;
  color: white;
}

.btnSubmit:hover{
  background-color: #aa2525;
}
